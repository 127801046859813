<template>
  <div>
<!--    <Banner></Banner>-->
    <div class="banner">
      <img src="~@/assets/imgs/nmt/product_poster.jpg" alt="">
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>产品优势</p>
          <p>PRODUCT ADVANTAGE</p>
        </div>
        <div class="hxjs-body">
          <div class="advantage_msg">
            <img src="~@/assets/imgs/nmt/adv_js.png" alt="">
            <p class="adv_title"><span>业界领先的核心技术</span></p>
            <p class="adv_det"><span>基于海量训练数据、业界领先的TDNN识别技术和端到端的翻译技术，提供优质服务。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/nmt/adv_bs.png" alt="">
            <p class="adv_title"><span>多场景应用</span></p>
            <p class="adv_det"><span>可以在多个场景快捷使用，包括商务、点餐、旅游、咨询等。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/nmt/adv_dz.png" alt="">
            <p class="adv_title"><span>多国语言翻译</span></p>
            <p class="adv_det"><span>支持多国语言翻译，支持离线不联网使用，可以实现不同环境下的使用。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/nmt/adv_hj.png" alt="">
            <p class="adv_title"><span>性能强大</span></p>
            <p class="adv_det"><span>使用四核A53，处理器性能强大，可实现长时间的会议也进行翻译。</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-grey">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>产品功能</p>
          <p>PRODUCT FUNCTION</p>
        </div>
        <div class="hy_cpgn_body">
          <div class="fun_msg">
            <img src="~@/assets/imgs/nmt/fun_hy.jpg" alt="">
            <div>
              <p class="fun_title"><span>75+语言拍照翻译</span></p>
              <p class="fun_det"><span>支持75+语种的拍照翻译，可以对图片的文字进行识别并且翻译。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/nmt/fun_yp.jpg" alt="">
            <div>
              <p class="fun_title"><span>超大电池容量</span></p>
              <p class="fun_det"><span>2000毫安电池，不惧任何长时间使用场景，电量无忧。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/nmt/fun_dz.jpg" alt="">
            <div>
              <p class="fun_title"><span>同声传译</span></p>
              <p class="fun_det"><span>可以实现同声传译功能，可以选择多种语言进行传译，实现您的无缝沟通。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/nmt/fun_du.jpg" alt="">
            <div>
              <p class="fun_title"><span>离线翻译</span></p>
              <p class="fun_det"><span>即使没有网络，也支持高达17+语种的离线翻译，使用无忧。</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hy_index_bg">
      <div class="hy_index_cpyss content-container">
        <div class="hy_index_title page-title">
          <p>产品演示</p>
          <p>PRODUCT DEMONSTRATION</p>
        </div>
        <div class="hy_cpys_body">
          <div class="video_box_diting">
            <video :src="videoSrc" controls preload :poster="posterSrc"></video>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>应用场景</p>
          <p>APPLICATION SCENARIOS</p>
        </div>
        <div class="hy_yycj_body">
          <div>
            <div>
              <img src="~@/assets/imgs/nmt/sce_icon_dh.png">
              <p>大会演讲</p>
              <i></i>
              <p><span>产品发布会，行业峰会，公司年会等开放性大型会场</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/nmt/sce_icon_xh.png">
              <p>小型会议</p>
              <i></i>
              <p><span>多人现场形式的组织会议、团队培训、专题讲座等中小型会场</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/nmt/sce_icon_sp.png">
              <p>视频会议</p>
              <i></i>
              <p><span>企业远程会议、跨国会议等视频会议场景</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>项目案例</p>
          <p>PROJECT CASES</p>
        </div>
        <div class="hy_xmal_body">
          <div class="swiper-container swiper" id="swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide s-slide-1">
                <div class="case_name">
                  <p><span>司法机关</span></p>
                  <p><span>慧言科技为某地法院部署了适用于庭审记录的会议系统</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>慧言科技为某地法院部署了适用于庭审记录的会议系统。针对庭审现场设备便携性的需求，提供了笔记本单机部署方案，并面向法律术语进行了热词优化，以提高识别准确率，书记员可在现场监督转写进度的同时，实时修改，提高记录存证工作效率。</span></p>
                </div>
              </div>
              <div class="swiper-slide  s-slide-2">
                <div class="case_name">
                  <p><span>通讯公司</span></p>
                  <p><span>某通讯公司面临会议频繁、记录整理耗时、会议室多而分散等问题</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>某通讯公司面临会议频繁、记录整理耗时、会议室多而分散等问题。针对该公司特点，慧言科技提供了内网部署方案，将智能会议系统核心服务部署在该公司自有服务器集群，实现高效会议同传和记录整理便于企业内部集中管控。 </span></p>
                </div>
              </div>
              <div class="swiper-slide  s-slide-3">
                <div class="case_name">
                  <p><span>高等院校</span></p>
                  <p><span>慧言科技为某高校部署了适用于上课的会议系统</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>慧言科技为某高校部署了适用于上课的会议系统。可将老师的讲课内容实时展示在屏幕上，便于学生查看，并支持翻译功能，满足不同国家学生的语言习惯。并支持将讲课内容以视频，音频，文本等格式进行分享，便于老师和学生的课后整理。</span></p>
                </div>
              </div>
              <div class="swiper-slide  s-slide-4">
                <div class="case_name">
                  <p><span>跨国企业</span></p>
                  <p><span>某公司因员工分布全球化，面临沟通的效率低，会议频繁等问题</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>某公司因员工分布全球化，面临沟通的效率低，会议频繁等问题。针对该公司的特点，慧言科技为该公司部署了多语种的智能会议系统，支持会议中过程进行语种切换，解决了跨国沟通问题。并支持多语种导出会议记录，提升工作效率。</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" id="swiper_prev2"></div>
          <div class="swiper-button-next" id="swiper_next2"></div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>合作伙伴</p>
          <p>COOPERATIVE PARTNER</p>
        </div>
        <div class="hzhb-body">
          <div><img src="~@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
          <div><img src="~@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
          <div><img src="~@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
          <div><img src="~@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
          <div><img src="~@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

          <div><img src="~@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
          <div><img src="~@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
          <div><img src="~@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
          <div><img src="~@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
          <div><img src="~@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

          <div><img src="~@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
          <div><img src="~@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
          <div><img src="~@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
          <div><img src="~@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
          <div><img src="~@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
        </div>
      </div>
    </div>
    <div class="page-container-grey">
      <div class="page-hzzx">
        <p><span>点击下方按钮，获得我们的专属支持</span></p>
        <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import Banner from './Banner.vue'
import Video1 from '../assets/imgs/nmt/meeting1.mp4'
import Poster1 from '../assets/imgs/nmt/bg_qp.png'
export default {
  name: 'meeting',
  data () {
    return {
      videoSrc: Video1,
      posterSrc: Poster1
    }
  },
  components: {
    Banner
  },
  mounted () {
    this.$nextTick(() => {
      new Swiper("#swiper2", {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 4,
        // loop: true,
        // loopFillGroupWithBlank: true,
        navigation: {
          nextEl: "#swiper_prev2",
          prevEl: "#swiper_prev2",
        }
      });
    })
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/product.css';
.banner {
  width: 100%;
  img {
    width: 100%;
  }
}
.hy_cpys_body{
  position: relative;
  width: 100%;
  height: 590px;
  background: url("~@/assets/imgs/nmt/dem_main.png") no-repeat center center;
  background-size: 100% 100%;
}

/*产品演示*/
.video_1,
.video_2{
  position: absolute;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 234px;
  cursor: pointer;
}
.video_1{
  top: 62px;
  background: url("~@/assets/imgs/nmt/dem_qp.jpg") no-repeat center center;
  background-size: 100% 100%;
}
.video_2{
  bottom: 20px;
  background: url("~@/assets/imgs/nmt/dem_zm.jpg") no-repeat center center;
  background-size: 100% 100%;
}
.video_1 p,
.video_2 p{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.video_1 p:hover,
.video_2 p:hover{
  background-color: rgba(0,0,0,0.5)!important;
}
.video_1 p span,
.video_2 p span{
  display: block;
  font-size: 30px;
  color: #fff;
  text-align: center;
}
.video_pic_box{
  position: absolute;
  top: 60px;
  bottom: 15px;
  right: 16px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  width: 256px;
}
.hy_yycj_body{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.hy_yycj_body>div{
  width: 380px;
  height: 285px;
  margin-bottom: 30px;
  background-size: 100% 100%!important;
}
.hy_yycj_body>div>div{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 380px;
  height: 285px;
  color: #fff;
}
.hy_yycj_body>div>div>img{
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
}
.hy_yycj_body>div>div>p:nth-child(2){
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
}
.hy_yycj_body>div>div>i{
  width: 40px;
  margin-bottom: 40px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.hy_yycj_body>div>div>p:nth-child(4){
  height: 0;
  padding: 0 50px;
  font-size: 14px;
  opacity: 0;
  -webkit-transition: height  0.6s;
  -moz-transition: height 0.6s;
  transition: height 0.6s;
}
.hy_yycj_body>div>div>p:nth-child(4) span{
  font-family: 'siyuan-normal', serif;
}
.hy_yycj_body>div>div:hover{
  background: rgba(0,73,242,0.56);
}
.hy_yycj_body>div>div:hover p:nth-child(4){
  height: 60px;
  opacity: 1;
}
.hy_yycj_body>div:nth-of-type(1) {
  background: url('~@/assets/imgs/nmt/sce_dh.jpg') no-repeat center center;
}
.hy_yycj_body>div:nth-of-type(2) {
  background: url('~@/assets/imgs/nmt/sce_xh.jpg') no-repeat center center;
}
.hy_yycj_body>div:nth-of-type(3) {
  background: url('~@/assets/imgs/nmt/sce_sp.jpg') no-repeat center center;
}

.s-slide-1 {
  background: url('~@/assets/imgs/nmt/case_img1.jpg') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-2 {
  background: url('~@/assets/imgs/nmt/case_img2.jpg') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-3 {
  background: url('~@/assets/imgs/nmt/case_img3.jpg') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-4 {
  background: url('~@/assets/imgs/nmt/case_img4.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>